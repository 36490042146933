






import { Component, Prop } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import Vue from 'vue'

import { empty } from '~/filters'
import RectangleChip from '~/components/common/RectangleChip.vue'
import SectionHandle from '~/components/dashboard/widget/collaborate/common/displays/SectionHandle.vue'

@Component({
  components: {
    RectangleChip,
    SectionHandle,
  },
  name: 'dashboard-collaborate-common-content-section-display',
  filters: {
    empty: empty('-'),
  },
})
export default class SectionDisplay extends Vue {
  @Prop()
  public task: TaskModel & object
}
