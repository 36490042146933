




import { Component, Prop } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import Vue from 'vue'

import { dateFormat } from '~/filters'
import config from '~/config'
@Component({
  name: 'dashboard-collaborate-common-content-group-display',
  filters: {
    dateFormat,
  },
})
export default class GroupDisplay extends Vue {
  @Prop()
  public task: TaskModel & object

  get groupName() {
    const group = this.$store.myGetters.getGroupName(this.task.boardId)
    return group ? group : ''
  }

  public handleGroupClick() {
    const collaborateApp = this.$store.myGetters['accounts/app/currentWorkApps'].find(
      app => app.name === config.appName.collaborate,
    )
    collaborateApp &&
      window.open(
        `${collaborateApp.url}/groups/${this.groupName}/boards/${this.task.boardId}`,
        '_blank',
      )
  }
}
