




import { Component, Prop, Vue } from 'vue-property-decorator'
import RectangleChip from '~/components/common/RectangleChip.vue'

@Component({
  components: {
    RectangleChip,
  },
  name: 'dashboard-collaborate-common-displays-board-group-chip',
})
export default class BoardGroupChip extends Vue {
  @Prop()
  public value: string
}
