





import { Component, Prop } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import Vue from 'vue'

@Component({
  name: 'dashboard-collaborate-common-content-section-handle',
})
export default class SectionHandle extends Vue {
  @Prop()
  public task: TaskModel & object

  get slotProps() {
    return {
      sectionDisplay: this.sectionDisplay,
    }
  }

  get creator() {
    return this.$store.myGetters['accounts/user/getById'](this.task.createdBy)
  }

  get parentTask() {
    return this.$store.myGetters.getTaskById(this.task.parentTaskId)
  }

  // Section display is base on collaborate pdf
  get sectionDisplay() {
    if (this.task.parentTaskId) {
      const parentTask = this.$store.myGetters.getTaskById(this.task.parentTaskId)
      return parentTask ? this.$store.myGetters.getGroupName(parentTask.boardId) : ''
    } else {
      const user = this.$store.myGetters['accounts/user/getById'](this.task.createdBy)
      return [user ? user.department : '', this.task.section].filter(str => str).join(' - ')
    }
  }
}
