






import { Component, Prop } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import Vue from 'vue'

import { empty } from '~/filters'
import config from '~/config'
@Component({
  filters: {
    empty: empty('-'),
  },
  name: 'dashboard-collaborate-common-content-description-display',
})
export default class DescriptionDisplay extends Vue {
  @Prop() public task: TaskModel & object

  get slotProps() {
    return {
      description: this.task.description,
      click: this.handleClick,
    }
  }

  public handleClick() {
    const collaborateApp = this.$store.myGetters['accounts/app/currentWorkApps'].find(
      app => app.name === config.appName.collaborate,
    )
    collaborateApp && window.open(`${collaborateApp.url}/locate-task/${this.task.id}`, '_blank')
  }
}
