













import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  name: 'dashboard-collaborate-common-displays-board-mobile-display',
})
export default class BoardMobileDisplay extends Vue {
  @Prop()
  public items: object[]
  @Prop({
    default: 'all',
  })
  public value: string
}
