








import { Component, Prop } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import Vue from 'vue'

import { dateFormat, empty } from '~/filters'

@Component({
  name: 'dashboard-collaborate-common-content-issue-date-display',
  filters: {
    dateFormat,
    empty: empty('-'),
  },
})
export default class IssueDateDisplay extends Vue {
  @Prop()
  public task: TaskModel & object

  @Prop({
    default: 'HH:mm',
  })
  public formatStr: string

  get issueDate() {
    const targetTask =
      this.task.source === 'shared'
        ? this.task
        : this.$store.myGetters.getParentTaskById(this.task.id)
    return targetTask.articleIssueDate ?? undefined
  }
}
