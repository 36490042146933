import { render, staticRenderFns } from "./EventDateDisplay.vue?vue&type=template&id=466883f4&scoped=true&lang=pug&"
import script from "./EventDateDisplay.vue?vue&type=script&lang=ts&"
export * from "./EventDateDisplay.vue?vue&type=script&lang=ts&"
import style0 from "./EventDateDisplay.vue?vue&type=style&index=0&id=466883f4&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "466883f4",
  null
  
)

export default component.exports