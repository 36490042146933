




import { Component, Prop } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import Vue from 'vue'

import { dateFormat } from '~/filters'
import config from '~/config'
@Component({
  name: 'dashboard-collaborate-common-content-board-display',
  filters: {
    dateFormat,
  },
})
export default class BoardDisplay extends Vue {
  @Prop()
  public task: TaskModel & object

  get boardName() {
    const board = this.$store.myGetters.getBoardById(this.task.boardId)
    return board ? board.name : ''
  }

  public handleBoardClick() {
    const collaborateApp = this.$store.myGetters['accounts/app/currentWorkApps'].find(
      app => app.name === config.appName.collaborate,
    )
    collaborateApp &&
      window.open(
        `${collaborateApp.url}/groups/${this.$store.myGetters.getGroupName(
          this.task.boardId,
        )}/boards/${this.task.boardId}`,
        '_blank',
      )
  }
}
