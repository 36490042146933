








import { Component, Prop } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import Vue from 'vue'

import { empty, eventDatetimeBuilder } from '~/filters'

@Component({
  name: 'dashboard-collaborate-common-content-event-date-display',
  filters: {
    empty: empty('-'),
    eventDatetimeBuilder,
  },
})
export default class EventDateDisplay extends Vue {
  @Prop()
  public task: TaskModel & object
}
