








import { Component, Prop } from 'vue-property-decorator'
import {
  TaskArticlePublishStatusModel,
  TaskModel,
} from '@technology/collaborate-next-js/dist/types/models'
import Vue from 'vue'

import { PublishStatusEvent } from '@technology/collaborate-next-js'
import { dateFormat } from '~/filters'
import CustomChip from '~/components/common/CustomChip.vue'
import util from '~/util'

@Component({
  components: {
    CustomChip,
  },
  name: 'dashboard-collaborate-common-pub-date-display',
  filters: {
    dateFormat,
  },
})
export default class PubDateDisplay extends Vue {
  @Prop() public task: TaskModel & object
  @Prop({
    default: () => [],
  })
  public publishStatuses: TaskArticlePublishStatusModel[]

  get conditionalFormatString() {
    return this.isActualDate ? 'DD MMM HH:mm' : 'DD MMM'
  }

  get isActualDate() {
    return this.hasPublishTime || this.hasScheduleTime
  }

  get hasPublishTime() {
    return this.publishes.length > 0
  }

  get hasScheduleTime() {
    const lastStatus = this.scheduleAndCancelledStatuses.slice(-1).pop()
    return lastStatus?.event === PublishStatusEvent.Scheduled
  }

  get scheduleAndCancelledStatuses() {
    return this.publishStatuses
      .filter(
        p => p.event === PublishStatusEvent.Scheduled || p.event === PublishStatusEvent.Cancelled,
      )
      .sort((a, b) => util.sortDate(a.updatedAt, b.updatedAt))
  }

  get publishes() {
    return this.publishStatuses
      .filter(p => p.event === PublishStatusEvent.Published)
      .sort((a, b) => util.sortDate(a.updatedAt, b.updatedAt))
  }

  get publishDate() {
    if (this.hasPublishTime) {
      const lastStatus = this.publishes.slice(-1).pop()
      return lastStatus!.updatedAt
    } else if (this.hasScheduleTime) {
      const lastStatus = this.scheduleAndCancelledStatuses.slice(-1).pop()
      return lastStatus!.scheduledPublishedAt
    } else if (this.task.source === 'shared') {
      return this.task.articleIssueDate
    }

    return this.$store.myGetters.getParentTaskById(this.task.id).articleIssueDate
  }
}
