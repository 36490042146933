






import { Component, Prop } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import { TaskStatus } from '@technology/collaborate-next-js'
import Vue from 'vue'
@Component({
  name: 'dashboard-collaborate-common-content-task-title-display',
})
export default class TaskTitleDisplay extends Vue {
  @Prop() public task: TaskModel & object

  get displayText() {
    return `# ${this.task.codeName} ${this.task.title}`
  }

  get titleClasses() {
    const classes = []
    if (this.task.status === TaskStatus.Cancelled) {
      classes.push('c-task-title-display--cancelled')
    } else if (this.task.status === TaskStatus.Rejected) {
      classes.push('c-task-title-display--rejected')
    } else if (this.task.assigneeIds?.length === 0) {
      classes.push('c-task-title-display--no-assignee')
    }
    return classes
  }
}
