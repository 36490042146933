




import { Component, Prop, Vue } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'

import { WorkflowStatus } from '@technology/collaborate-next-js'
import { dateFormat } from '~/filters'
import config from '~/config'
import constant from '~/components/dashboard/widget/collaborate/common/constant'

@Component({
  name: 'dashboard-collaborate-common-content-task-workflow-status-display',
  filters: {
    dateFormat,
  },
})
export default class TaskWorkflowStatusDisplay extends Vue {
  @Prop()
  public task: TaskModel & object

  get workflowStatus() {
    return config.workflowStatuses.find(ws => ws.value === this.task.workflowStatus)?.key
  }

  get color() {
    switch (this.task.workflowStatus.toLowerCase()) {
      case WorkflowStatus.Created:
        return constant.workflowStatusColor.created
      case WorkflowStatus.Declined:
        return constant.workflowStatusColor.declined
      case WorkflowStatus.Approved:
        return constant.workflowStatusColor.approved
      case WorkflowStatus['On Hold']:
        return constant.workflowStatusColor.onhold
      default:
        return '#000'
    }
  }
}
