








import { Component, Prop } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import Vue from 'vue'

import { empty } from '~/filters'
@Component({
  name: 'dashboard-collaborate-common-content-contact-info-display',
  filters: {
    empty: empty('-'),
  },
})
export default class ContactInfoDisplay extends Vue {
  @Prop()
  public task: TaskModel & object
}
