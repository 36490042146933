




import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'dashboard-collaborate-common-displays-cancelled-chip',
})
export default class CancelledChip extends Vue {}
