





import { CategoryModel } from '@technology/collaborate-next-js/dist/types/models'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

import { empty } from '~/filters'
import CustomChip from '~/components/common/CustomChip.vue'
@Component({
  name: 'dashboard-collaborate-common-category-display',
  components: {
    CustomChip,
  },
  filters: {
    empty: empty('-'),
  },
})
export default class CategoryDisplay extends Vue {
  @Prop()
  public category: CategoryModel & object

  get bgColor() {
    return this.category.style?.bgColor
  }

  get textColor() {
    return this.category.style?.textColor
  }
}
