

























import {
  CategoryModel,
  NewsPublishModel,
  TaskModel,
} from '@technology/collaborate-next-js/dist/types/models'
import { Component, Prop } from 'vue-property-decorator'
import { TaskStatus } from '@technology/collaborate-next-js'
import Vue from 'vue'

import CancelledChip from '~/components/dashboard/widget/collaborate/common/displays/CancelledChip.vue'
import CategoryDisplay from '~/components/dashboard/widget/collaborate/common/displays/CategoryDisplay.vue'
import RejectedChip from '~/components/dashboard/widget/collaborate/common/displays/RejectedChip.vue'
import TaskWorkflowStatusDisplay from '~/components/dashboard/widget/collaborate/common/displays/TaskWorkflowStatusDisplay.vue'

import { empty } from '~/filters'
import config from '~/config'
@Component({
  components: {
    CancelledChip,
    CategoryDisplay,
    RejectedChip,
    TaskWorkflowStatusDisplay,
  },
  filters: {
    empty: empty('-'),
  },
  name: 'dashboard-collaborate-common-content-task-with-article-display',
})
export default class TaskWithArticleTitleDisplay extends Vue {
  @Prop()
  public task: TaskModel & object
  @Prop()
  public newsPublish: NewsPublishModel & object
  @Prop()
  public category: CategoryModel & object
  @Prop()
  public parentTask: TaskModel & object

  get slotProps() {
    return {
      title: this.task.title,
      click: this.handleTitleClick,
    }
  }

  get showCancelledChip() {
    return this.task.status === TaskStatus.Cancelled
  }

  get showRejectedChip() {
    return this.task.status === TaskStatus.Rejected
  }

  get printSection() {
    return this.newsPublish?.printSection || ''
  }

  get suggestPrintRank() {
    return this.parentTask.articleSuggestToPrint || ''
  }

  public handleTitleClick() {
    const collaborateApp = this.$store.myGetters['accounts/app/currentWorkApps'].find(
      app => app.name === config.appName.collaborate,
    )
    collaborateApp && window.open(`${collaborateApp.url}/locate-task/${this.task.id}`, '_blank')
  }
}
