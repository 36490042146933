













import { Component, Prop } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import { TaskStatus } from '@technology/collaborate-next-js'
import Vue from 'vue'
import config from '~/config'

import BoardGroupChip from '~/components/dashboard/widget/collaborate/common/displays/BoardGroupChip.vue'
import CancelledChip from '~/components/dashboard/widget/collaborate/common/displays/CancelledChip.vue'
import RejectedChip from '~/components/dashboard/widget/collaborate/common/displays/RejectedChip.vue'

import { empty } from '~/filters'
import DescriptionDisplay from '~/components/dashboard/widget/collaborate/common/displays/DescriptionDisplay.vue'
import TaskTitleDisplay from '~/components/dashboard/widget/collaborate/common/displays/TaskTitleDisplay.vue'
@Component({
  components: {
    BoardGroupChip,
    CancelledChip,
    DescriptionDisplay,
    RejectedChip,
    TaskTitleDisplay,
  },
  filters: {
    empty: empty('-'),
  },
  name: 'dashboard-collaborate-common-content-task-wrapper-display',
})
export default class TaskWrapperDisplay extends Vue {
  @Prop() public task: TaskModel & object

  get showCancelledChip() {
    return this.task.status === TaskStatus.Cancelled
  }

  get showRejectedChip() {
    return this.task.status === TaskStatus.Rejected
  }

  get groupName() {
    return this.$store.myGetters.getGroupName(this.task.boardId)
  }

  public handleLayoutClick() {
    const collaborateApp = this.$store.myGetters['accounts/app/currentWorkApps'].find(
      app => app.name === config.appName.collaborate,
    )
    collaborateApp && window.open(`${collaborateApp.url}/locate-task/${this.task.id}`, '_blank')
  }
}
