







import { Component, Prop } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import { TaskType } from '@technology/collaborate-next-js'
import Vue from 'vue'

import CustomChip from '~/components/common/CustomChip.vue'

import { empty } from '~/filters'
@Component({
  components: {
    CustomChip,
  },
  filters: {
    empty: empty('-'),
  },
  name: 'dashboard-collaborate-common-content-requester-display',
})
export default class RequesterDisplay extends Vue {
  @Prop() public task: TaskModel & object

  get parentTask() {
    return this.$store.myGetters.getTaskById(this.task.parentTaskId)
  }

  get requester() {
    return this.$store.myGetters['accounts/user/getById'](this.task.createdBy)
  }

  // Separate requester and section
  get requesterDisplay() {
    if (this.task.taskType === TaskType.Request) {
      const creator = this.$store.myGetters['accounts/groupUser/getById'](this.task.createdBy)
      return creator ? this.$store.myGetters.getGroupName(creator.groupId) : ''
    } else if (this.task.parentTaskId) {
      return this.$store.myGetters.getGroupName(this.parentTask.boardId) ?? ''
    } else {
      return this.$store.myGetters.getGroupName(this.task.boardId) ?? ''
    }
  }
}
