




import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'dashboard-collaborate-common-displays-rejected-chip',
})
export default class RejectedChip extends Vue {}
