import { render, staticRenderFns } from "./TaskTitleDisplay.vue?vue&type=template&id=2460c2ce&scoped=true&lang=pug&"
import script from "./TaskTitleDisplay.vue?vue&type=script&lang=ts&"
export * from "./TaskTitleDisplay.vue?vue&type=script&lang=ts&"
import style0 from "./TaskTitleDisplay.vue?vue&type=style&index=0&id=2460c2ce&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2460c2ce",
  null
  
)

export default component.exports