




import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'dashboard-collaborate-common-displays-no-assignee-chip',
})
export default class NoAssigneeChip extends Vue {}
